export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
      },
      {
        _name: "CSidebarNavItem",
        name: "Class List",
        to: "/dashboard/classlist",
        icon: "cil-book",
      },
      {
        _name: "CSidebarNavItem",
        name: "Student List",
        to: "/dashboard/studentlist",
        icon: "cil-list",
      },
      {
        _name: "CSidebarNavItem",
        name: "Year List",
        to: "/dashboard/yearlist",
        icon: "cil-list",
      },
      {
        _name: "CSidebarNavItem",
        name: "Subject List",
        to: "/dashboard/subjectlist",
        icon: "cil-book",
      },
      {
        _name: "CSidebarNavItem",
        name: "Teacher List",
        to: "/dashboard/teacherlist",
        icon: "cil-user",
      },
      {
        _name: "CSidebarNavItem",
        name: "Division List",
        to: "/dashboard/divisionlist",
        icon: "cil-list",
      },
      {
        _name: "CSidebarNavItem",
        name: "School List",
        to: "/dashboard/school",
        icon: "cil-list",
      },
      {
        _name: "CSidebarNavItem",
        name: "Profile",
        to: "/profile",
        icon: "cil-paper-plane",
      },
      
      {
        _name: "CSidebarNavItem",
        name: "Study Material",
        to: "/upload_material",
        icon: "cil-paper-plane",
      },

      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Profile",
      //   route: "/profile",
      //   icon: "cil-book",
      //   items: [
      //     {
      //       name: "View Books",
      //       to: "/view-books",
      //     },
      //     {
      //       name: "Library",
      //       to: "/view-books/library",
      //     },
      //     {
      //       name: "Completed",
      //       to: "/view-books/completed",
      //     },
      //     {
      //       name: "Favorite",
      //       to: "/view-books/favorite",
      //     },
      //   ],
      // },

      {
        _name: "CSidebarNavDropdown",
        name: "Mapping",
        route: "/my-account",
        icon: "cil-puzzle",
        items: [
          {
            name: "Teacher - Class",
            to: "/teacherClass",
          },
          {
            name: "Student - Teacher",
            to: "/studentClass",
          },
        ],
      },
    ],
  },
];
