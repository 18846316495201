export default [
    {
      _name: "CSidebarNav",
      _children: [
        {
          _name: "CSidebarNavItem",
          name: "School Dashboard",
          to: "/dashboard",
          icon: "cil-speedometer",
        },
        {
          _name: "CSidebarNavItem",
          name: "Class List",
          to: "/dashboard/classlist",
          icon: "cil-user",
        },
        {
          _name: "CSidebarNavItem",
          name: "Subject List",
          to: "/dashboard/subjectlist",
          icon: "cil-user",
        },
        {
          _name: "CSidebarNavItem",
          name: "Profile",
          to: "/profile",
          icon: "cil-paper-plane",
        },
       
        {
          _name: "CSidebarNavItem",
          name: "Study Material",
          to: "/teacher_study_material",
          icon: "cil-paper-plane",
        },
  
        // {
        //   _name: "CSidebarNavDropdown",
        //   name: "Profile",
        //   route: "/profile",
        //   icon: "cil-book",
        //   items: [
        //     {
        //       name: "View Books",
        //       to: "/view-books",
        //     },
        //     {
        //       name: "Library",
        //       to: "/view-books/library",
        //     },
        //     {
        //       name: "Completed",
        //       to: "/view-books/completed",
        //     },
        //     {
        //       name: "Favorite",
        //       to: "/view-books/favorite",
        //     },
        //   ],
        // },
  
        {
          _name: "CSidebarNavDropdown",
          name: "Mapping",
          route: "/my-account",
          icon: "cil-puzzle",
          items: [
            {
              name: "Teacher - Class",
              to: "/teacherClass",
            },
            {
              name: "Student - Teacher",
              to: "/studentClass",
            },
          ],
        },
      ],
    },
  ];
  