<template>
  <CHeader fixed with-subheader light class="mahavidya-header">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo-mobile" height="28" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          {{user_role}}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CForm inline>
          <CCollapse :show="isCollapsed" :duration="400">
            <CInput
              class="mr-0 mb-0"
              placeholder="Search"
              size="sm"
              :show="isCollapsed"
              :duration="400"
            />
          </CCollapse>

          <CHeaderNavLink>
            <!-- <CLink
              class="card-header-action btn-minimize text-white c-header-nav-link"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon name="cil-search" />
            </CLink> -->
          </CHeaderNavLink>
        </CForm>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav class="">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <!-- <CHeaderNavLink>
          <CIcon name="cil-bell" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open" />
        </CHeaderNavLink>-->
      </CHeaderNavItem> 
      <TheHeaderDropdownAccnt class="profile-dropdown" />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },

  data: function() {
    return {
      show: true,
      isCollapsed: false,
      user_role:""
    };
  },
  created(){
    this.user_role = localStorage.getItem('user_role_id');
  }
};
</script>
